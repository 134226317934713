import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonLogic, useStyles, PropTypes } from './button.module';

const ButtonDefault = (props) => {
  const classes = useStyles();
  const { values, text } = ButtonLogic(props);

  return (
    <Button
      variant="outlined"
      className={classes[values.buttonClass]}
      id={values.id}
      onClick={values.changeView}
      type={values.isSubmit}
      endIcon={values.endIcon}
      href={values.href}
      target={values.target}
      disabled={props.disabled}
      name={values.name}
      style={{
        display: 'inline-flex',
        lineHeight: 1,
        padding: '0px 10px',
      }}
    >
      {(text || props.children) && (
        <>
          <div>{text}</div>

          {props.children && (
            <div>
              <span>{props.children}</span>
            </div>
          )}
        </>
      )}
    </Button>
  );
};

ButtonDefault.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  changeView: PropTypes.func,
  isSubmit: PropTypes.bool,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonDefault;
