import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Drawer,
} from '@material-ui/core';
import {
  useStyles,
  CarbonCreditsSearcherLogic,
} from './carbonCreditsSearcher.module';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NftTableLogic, csv, ftp } from '../shared/NftTable/NftTable.module';
import DrawenNftTable from '../shared/NftTable/DrawenNftTable';

const TableCarbonCreditsSearcher = ({ data }) => {
  const classes = useStyles();
  const { translator } = CarbonCreditsSearcherLogic();
  const STELLAR_URL = process.env.REACT_APP_STELLAR_URL;
  const {
    loadingCsvItemId,
    loadingGhgItemId,
    drawerOpen,
    toggleDrawer,
    carbonFluxData,
    handleCsvClick,
    handleGhgClick,
  } = NftTableLogic();

  return (
    <>
      <Paper className={classes.paperTableCarbonCredits}>
        <Typography className={classes.titleFirstTableCarbonCredits}>
          {translator('carbonCredits.aboutFirst')}
        </Typography>
        <TableContainer component={Paper} style={{ overflow: 'hidden' }}>
          <Table className={classes.tableCarbonCredit}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {' '}
                    {translator('carbonCredits.current')}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {' '}
                    {translator('carbonCredits.ownershipDate')}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  {data.userPublicKey && (
                    <div className={classes.contentColumnCarbonCredit}>
                      <span
                        variant="subtitle1"
                        className={classes.textBodyTableCarbonCredits}
                      >
                        {data.userPublicKey}
                      </span>
                      <Tooltip title={translator('carbonCredits.openStellar')}>
                        <IconButton
                          onClick={() =>
                            window.open(
                              `${STELLAR_URL}/accounts/${data.userPublicKey}`,
                              '_blank'
                            )
                          }
                          aria-label="open-carbonCredit-stellar"
                          className={classes.iconOpenCarbonCredits}
                        >
                          <InsertLinkIcon
                            className={classes.textHeaderTableCarbonCredits}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textBodyTableCarbonCredits}
                  >
                    {data.bought_at}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.used')}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {' '}
                    {translator('carbonCredits.removal')}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textBodyTableCarbonCredits}
                  >
                    {data.used_at || data.used_by ? 'Sí' : 'No'}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  {data.carbonflux?.length > 0 && (
                    <div className={classes.contentColumnCarbonCredit}>
                      <span
                        variant="subtitle1"
                        className={classes.textBodyTableCarbonCredits}
                      >
                        {data.carbonflux?.length}
                      </span>
                      <Tooltip title={translator('carbonCredits.viewDetails')}>
                        <IconButton
                          onClick={toggleDrawer(true, data.id)}
                          aria-label="view-carbonflux"
                          className={classes.iconOpenCarbonCredits}
                        >
                          <VisibilityIcon
                            className={classes.textHeaderTableCarbonCredits}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.issuer')}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  {data.issuing_account_public && (
                    <div className={classes.contentColumnCarbonCredit}>
                      <span
                        variant="subtitle1"
                        className={classes.textBodyTableCarbonCredits}
                      >
                        {data.issuing_account_public}
                      </span>
                      <Tooltip title={translator('carbonCredits.openStellar')}>
                        <IconButton
                          onClick={() =>
                            window.open(
                              `${STELLAR_URL}/accounts/${data.issuing_account_public}`,
                              '_blank'
                            )
                          }
                          aria-label="open-carbonCredit-stellar"
                          className={classes.iconOpenCarbonCredits}
                        >
                          <InsertLinkIcon
                            className={classes.textHeaderTableCarbonCredits}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography className={classes.titleSecondTableCarbonCredits}>
          {translator('carbonCredits.aboutSecond')}
        </Typography>
        <TableContainer>
          <Table className={classes.tableCarbonCredit}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.removalPublic')}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.removalActivation')}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  {data.projectPublicKey && (
                    <div className={classes.contentColumnCarbonCredit}>
                      <span
                        variant="subtitle1"
                        className={classes.textBodyTableCarbonCredits}
                      >
                        {data.projectPublicKey}
                      </span>
                      <Tooltip title={translator('carbonCredits.openStellar')}>
                        <IconButton
                          onClick={() =>
                            window.open(
                              `${STELLAR_URL}/accounts/${data.projectPublicKey}`,
                              '_blank'
                            )
                          }
                          aria-label="open-carbonCredit-stellar"
                          className={classes.iconOpenCarbonCredits}
                        >
                          <InsertLinkIcon
                            className={classes.textHeaderTableCarbonCredits}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textBodyTableCarbonCredits}
                  >
                    {data.activation_date}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.removalLast')}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCreditHeader}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textHeaderTableCarbonCredits}
                  >
                    {translator('carbonCredits.removalInventory')}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textBodyTableCarbonCredits}
                  >
                    {data.last_transaction}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.columnCarbonCredit}
                >
                  <span
                    variant="subtitle1"
                    className={classes.textBodyTableCarbonCredits}
                  >
                    {data.current_inventory}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawenNftTable
          carbonFluxData={carbonFluxData}
          translator={translator}
          handleCsvClick={handleCsvClick}
          handleGhgClick={handleGhgClick}
          loadingCsvItemId={loadingCsvItemId}
          loadingGhgItemId={loadingGhgItemId}
          csv={csv}
          ftp={ftp}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </>
  );
};

export default TableCarbonCreditsSearcher;
