import {  makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerCarbonCredits: {
    marginTop: '120px',
    // padding: '20px 30px',
    padding: '30px 30px 20px 30px',
    marginBottom: 63,
    textAlign: 'center', 
    backgroundColor: 'rgba(255, 255, 255, 0.15)', 
    borderRadius: '15px', 
    backdropFilter: 'blur(8px)', 
  },
  inputCarbonCredits: {
    background: '#FFF',
    borderRadius: 10,
    fontFamily: 'Montserrat',
    color: '#333',
    fontSize: '16px',
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CCC',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(44, 221, 0)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(44, 221, 0)',
      boxShadow: '0px 0px 10px rgba(44, 221, 0, 0.6)',
    },
  },
  buttonCarbonCredits: {
    width: '100%',
    fontFamily: 'Montserrat',
    borderRadius: '25px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    padding: '12px 16px',
    color: '#fff',
    background: 'linear-gradient(90deg, rgb(44, 221, 0) 0%, rgb(30, 180, 0) 100%)',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(30, 180, 0) 0%, rgb(44, 221, 0) 100%)',
      transform: 'scale(1.07)',
    },
    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
      color: '#fff',
      background: '#BDBDBD',
    },
  },
  helperTextCarbonCredits: {
    width: '100%',
    color: theme.palette.text.dark,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
    textAlign: 'center',
  },
  
  paperTableCarbonCredits:{
    marginTop:15,
    marginBottom:15,
    padding: ' 0px 20px 20px 20px' ,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #00DD9E',
    borderRadius: 20,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  },
  titleFirstTableCarbonCredits:{
    color: theme.palette.text.secondary,
    margin:'15px 0px',
    fontFamily: 'MontserratSemiBold',
    fontSize: '25px'
  },
  titleSecondTableCarbonCredits:{
    color: theme.palette.text.secondary,
    margin:'15px 0px',
    fontFamily: 'MontserratSemiBold',
    fontSize: '25px'
  },
  
  textHeaderTableCarbonCredits:{
    color: theme.palette.text.dark,
    fontFamily: 'MontserratSemiBold',
  },
  textBodyTableCarbonCredits:{
    color: theme.palette.text.nonary,
    fontFamily: 'Montserrat',
    overflowWrap: 'break-word', 
    wordBreak: 'break-word', 
    whiteSpace: 'normal', 
  },
  tableCarbonCredit: {
    border: `1px solid ${theme.palette.borderSelect.borderDark}`,
    borderCollapse: 'collapse',
  },

  columnCarbonCredit:{
    border: `1px solid ${theme.palette.borderSelect.borderDark}!important `,
  },
  columnCarbonCreditHeader:{
    border: `1px solid ${theme.palette.borderSelect.borderDark}!important `,
    backgroundColor: theme.palette.background.dark
  },

  iconOpenCarbonCredits:{
    padding: 0,
     margin: 0, 
     marginLeft: 6
  },
  appBarDialogCarbonCredits: {
      position: 'relative',
    },
    titleDialogCarbonCredits: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: theme.palette.text.dark,
      fontFamily: 'MontserratSemiBold',
      lineHeight: -2,
      fontSize: '25px',
      [theme.breakpoints.up('xs')]: {
        fontSize: '17px',
      },
    },
    contentColumnCarbonCredit:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

}));

export default useStyles;
