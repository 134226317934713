import React, { useEffect, useState } from 'react';
import { KeyIndicatorsLogic, useStyles } from './keyIndicators.module';
import ProjectKeys from '../ProjectKeys/projectkeys';
import ProjectKey from '../../classes/Project/ProjectKey';
import { Grid } from '@material-ui/core';

export default function KeysIndicatorsList({ info, keys }) {
  const classes = useStyles();
  const { translator } = KeyIndicatorsLogic();
  const [projectKeys, setProjectKeys] = useState([]);

  useEffect(() => {
    setProjectKeys(keys);
  }, [keys]);

  return (
    <Grid container className={classes.keyIndicatorsListContainer}>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h2 className={classes.sectionTitle}>
              {translator('keyIndicatorsList.title')}
            </h2>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.keysIndicators}>
              {info.project.technology ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'technology'}
                    name={'Technology:'}
                    data={info.project.technology}
                  />
                </div>
              ) : null}

              {ProjectKey.findKeyBasedOnName({
                keys: projectKeys,
                name: 'Carbon Credit Forwards*:',
              }) ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'cc_forwards_inventory'}
                    projectKey={ProjectKey.findKeyBasedOnName({
                      keys: projectKeys,
                      name: 'Carbon Credit Forwards*:',
                    })}
                  />
                </div>
              ) : null}

              {ProjectKey.findKeyBasedOnName({
                keys: projectKeys,
                name: 'Carbon Credit forward purchases:',
              }) ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'cc_forward_purchase'}
                    projectKey={ProjectKey.findKeyBasedOnName({
                      keys: projectKeys,
                      name: 'Carbon Credit forward purchases:',
                    })}
                  />
                </div>
              ) : null}

              {ProjectKey.findKeyBasedOnName({
                keys: projectKeys,
                name: 'Carbon Credit Inventory*:',
              }) ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'cc_forwards_inventory'}
                    projectKey={ProjectKey.findKeyBasedOnName({
                      keys: projectKeys,
                      name: 'Carbon Credit Inventory*:',
                    })}
                  />
                </div>
              ) : null}

              {ProjectKey.findKeyBasedOnName({
                keys: projectKeys,
                name: 'Carbon Credit purchases:',
              }) ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'cc_forward_purchase'}
                    projectKey={ProjectKey.findKeyBasedOnName({
                      keys: projectKeys,
                      name: 'Carbon Credit purchases:',
                    })}
                  />
                </div>
              ) : null}

              {ProjectKey.findKeyBasedOnName({
                keys: projectKeys,
                name: 'Last Transaction:',
              }) ? (
                <div className={classes.keysIndicatorsItem}>
                  <ProjectKeys
                    key={'cc_purchase'}
                    projectKey={ProjectKey.findKeyBasedOnName({
                      keys: projectKeys,
                      name: 'Last Transaction:',
                    })}
                  />
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      <span className={classes.infoSpan}>(*) tons of CO₂</span>
    </Grid>
  );
}
