import Translator from '../../classes/Translator/Translator';

const PoliciesLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default PoliciesLogic;
