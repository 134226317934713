import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 
  flexContainer: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'flex-start', 
    margin: '3em 0', 
  },
  textHeader: {
    fontFamily: 'Montserrat',

    fontWeight: 600, 
    letterSpacing: '1.44px',
    textAlign: 'left',
    color: theme.palette.text.denary,
    fontSize: 'clamp(32px, 5vw, 72px)', 
    lineHeight: '1.2',
    '@media screen and (max-width: 768px)': {
      fontSize: '48px', 
    },
    '@media screen and (max-width: 576px)': {
      fontSize: '36px', 
    },
  },
  textFooter: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.palette.text.denary,
    margin: '3em 0px 1em 0px'
  },

}));

export default useStyles;
