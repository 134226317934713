
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  containerDocumentation: {
    backgroundColor: '#D9D9D9',
    borderRadius: '12px',
    padding: '20px',
    textAlign: 'center',
    color: 'black',
   
  },
  documentationTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 26,
    fontWeight: 700,
    color: 'black',
    marginBottom: 20,
  },
  contentWrapper: {
    background: 'transparent',
  },
  documentationSectionContainer: {
    paddingBottom: 15,
    marginBottom: 15,
  },
  textArrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: '0.3s ease',
    '&:hover': {
      color: '#00DD9E',
    },
  },
  documentationSectionTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    fontSize: 20,
    borderBottom: '3px solid #00DD9E',
    textAlign:'left'

  },
  documentationSectionText: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    color: '#333',
    textAlign:'left'

  },
  arrow: {
    color: '#00DD9E',
    fontSize: 24,
  },
  lockIcon: {
    color: '#00DD9E',
  },
  infoText: {
    fontSize: 18,
    color: '#888',
  },
}));

export default useStyles;
