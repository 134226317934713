import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  keyIndicatorsListContainer: {
    fontSize: 24,
    textAlign: 'left',
    borderRadius: '20px',
    border: '2px solid #00DD9E',
    marginTop: 20,
    padding: 24,
    fontWeight: 700,
    height: '100%',
    boxShadow: '0px 4px 12px rgba(0, 221, 158, 0.2)',
  },
  sectionTitle: {
        display: 'flex',         
    alignItems: 'flex-start',      
    justifyContent: 'flex-start', 
    minHeight: '120px',       
      padding: '0 10px',       
      overflowWrap: 'break-word', 
    wordBreak: 'break-word', 
    whiteSpace: 'normal', 


    fontFamily: 'MontserratBold',
    color: '#00DD9E',
    fontSize: '48px',
    lineHeight: '55px',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '10px',
  },
  iconTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
  },
 
  keysIndicators: {
    display: 'grid',
    gridTemplateColumns: '1fr', 
    gap: '12px',
  },
 
  infoSpan: {
    fontSize: '15px',
    margin: '40px',
  },
  keysIndicatorsItem:{
    width: '100%', 
    padding: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      transform: 'scale(1.02)',
    },
  }
}));
export default useStyles;
