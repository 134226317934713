import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  imageInfoSensors: (props) => ({
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    marginBottom: props.itemCount > 2 ? 50 : 0, 
  }),
 
  containerInfoSensors: {
    borderRadius: '20px',
    border: '2px solid #00DD9E',
    margin: '40px 0px 40px 0px',
    padding: 7,
    [theme.breakpoints.down('md')]: {
      padding: 3,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  titleInfoSensors: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '28px',
    fontWeight: 700, 
    lineHeight: '1.3', 
    letterSpacing: '0.5px', 
    textAlign: 'left', 
    color: theme.palette.text.primary, 
    marginBottom: '16px', 
    padding: '0 10px', 
  },
  
  subtitleInfoSensors: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '22px', 
    fontWeight: 600, 
    lineHeight: '1.4',
    letterSpacing: '0.3px',
    textAlign: 'left',
    color: theme.palette.text.secondary, 
    display: 'flex',
    alignItems: 'center', 
    gap: '8px', 
    marginBottom: '12px', 
  },
  
  bodyInfoSensors: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '20px', 
    fontWeight: 400, 
    lineHeight: '1.6', 
    letterSpacing: '0.5px', 
    textAlign: 'left', 
    color: theme.palette.text.denary,
    wordBreak: 'break-word', 
    maxWidth: '600px', 
    margin: 'auto', 
    padding: '10px 0', 
    
    height:'100%'
  },
  
    
  iconInfoSensors: {
    fontSize: '20px !important', 
    color: '#00DD9E !important',
  },
  itemInfoSensors: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 5,

  },
  itemImageInfoSensors: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems:'flex-start',
    height:'100%',
  },
}));

export default useStyles;
