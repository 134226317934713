import React from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useStyles } from './creditExplanation.module';

const CreditExplanation = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.rootCreditExplanation}>
      <Grid item xs={12}>
        <Typography className={classes.titleCreditExplanation}>
          {props.title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List className={classes.listContainer}>
          {[props.nft_description, props.sbt_description].map((desc, index) => (
            <ListItem key={index} className={classes.listItem}>
              <ListItemText
                primary={
                  <Typography
                    component="p"
                    variant="body1"
                    className={classes.descriptionCreditExplanation}
                  >
                    {desc}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CreditExplanation;
