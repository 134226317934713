import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    bodyAboutEgreen: {
        fontFamily: 'MontserratLight',
        fontWeight: 500,
        fontSize: '25px',
        lineHeight: '32px', 
        color: theme.palette.text.tertiary,
        textAlign: 'left',
        letterSpacing:'0em',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
          lineHeight: '28px',
        },
      },
      
      moreAboutEgreen: {
        fontFamily: 'MontserratRegular',
        fontSize: '25px',
        fontWeight: 500,
        lineHeight: '32px', 
        textAlign: 'left',
        marginTop: 30,
      },
      linkAboutEgreen: {
        color: '#00DD9E', 
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      bodyAboutEgreenStrong: {
        fontFamily: 'MontserratRegular !important',
        fontWeight: 700, 
        paddingLeft:5,
        paddingRight:5,
        color: theme.palette.text.tertiary,

   
      }, 

}));

export default useStyles;

