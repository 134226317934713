// src/components/Policies/Policies.js
import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import { PoliciesLogic, useStyles} from './policies.module'

const Policies = () => {
    const { translator } = PoliciesLogic();
    const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.container}>
      <Box py={4}>
        <Typography variant="h3" gutterBottom className={classes.title}>
        {translator('policies')}
        </Typography>
        <Typography variant="body1">
        {translator('policies.text')}
        </Typography>
      </Box>
    </Container>
  );
};

export default Policies;