import { Column } from '@ant-design/charts';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  impactContainer: {
    fontSize: 24,
    textAlign: 'left',
    borderRadius: '20px',
    border: '2px solid #00DD9E',
    
    marginTop: 20,
    padding: 24,
    fontWeight: 700,
    height: '100%',
    boxShadow: '0px 4px 12px rgba(0, 221, 158, 0.2)',
  },
  title: {
            display: 'flex',         
    alignItems: 'flex-start',     
    justifyContent: 'flex-start',
    minHeight: '120px',       
      padding: '0 10px',      

    fontFamily: 'MontserratBold',
    color: '#00DD9E',
    fontSize: '48px',
    lineHeight: '55px',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '10px',
    overflowWrap: 'break-word', 
    wordBreak: 'break-word', 
    whiteSpace: 'normal', 

  },
  containerProjectKeys: {
    display: 'grid',
    gridTemplateColumns: '1fr', 
    gap: '12px',
  },
  itemProjectKeys: {
    width: '100%', 
    padding: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      transform: 'scale(1.02)',
    },
  },
}));
export default useStyles;
