import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles, AboutEgreenLogic } from './aboutEgreen.module';

const AboutEgreen = ({ name }) => {
  const classes = useStyles();
  const { translator } = AboutEgreenLogic();

  return (
    <>
      <Typography className={classes.bodyAboutEgreen}>
        {name !== 'Esmeralda Rosa' && (
          <>
            {translator('egreenGuarantee.firstDescription')} <br />
            {translator('egreenGuarantee.secondDescription')}
            <span className={classes.bodyAboutEgreenStrong}>
              {translator('egreenGuarantee.thirdDescription')}
            </span>
            {translator('egreenGuarantee.fourthDescription')}
          </>
        )}
        {name === 'Esmeralda Rosa' &&
          translator('egreenGuarantee.description2')}
      </Typography>

      <Typography className={classes.moreAboutEgreen}></Typography>
    </>
  );
};

export default AboutEgreen;
