import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customToolbarMenu: {     
        '& .apexcharts-menu-item': {
            color: '#041370',
            fontFamily: 'Montserrat',
        },
        '& .apexcharts-menu-item:hover': {
            backgroundColor:'#06E89F !important',
        },
    },
    buttonGroupDmrvChart: {
        marginBottom: '15px',
        borderRadius: '8px',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '100%',
      },
      buttonDmrvChart: {
        flex: 1,
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        backgroundColor: '#00DD9E',
        color: '#041370',
        transition: '0.3s',
        '&:hover': {
          backgroundColor: '#05C98E',
        },
      },
      buttonActiveDmrvChart: {
        flex: 1,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: '16px',
        backgroundColor: '#05C98E',
        color: '#fff',
      },
}));
export default useStyles;

