import { projectKeysLogic, useStyles } from './projectKeys.module';
import keys from '../../classes/Project/ProjectKey';
import ModalComponent from '../shared/Modal/Modal';

export default function ProjectKeys(props) {
  const classes = useStyles();
  const { handleClick, name, data } = projectKeysLogic(props);

  const { infoModal } = {
    ...keys,
    ...props,
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.Title}>{name}</div>

      <div className={classes.textData}>{data}</div>
      <ModalComponent /* open={openModal} onChange={onChange} */>
        {infoModal}
      </ModalComponent>
    </div>
  );
}
