import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Grid,
} from '@material-ui/core';
import { useStyles, ProjectCardLogic, PropTypes } from './projectCard.module';
import ButtonDefault from '../button/button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const ProjectCard = (props) => {
  const classes = useStyles();
  const { values, goToProject } = ProjectCardLogic(props);
  const buttonDisabled = values.project.only_showing && !values.project.show_without_purchase;
  return (
    <Card className={classes.root}>
      <CardActionArea
        classes={{ focusHighlight: classes.focus }}
        TouchRippleProps={{ className: classes.ripple }}
      >
        <CardMedia
          onClick={() => {}}
          className={classes.media}
          image={values.project.image}
          title="Project Main Image"
          children={
            <Typography
              className={classes.country}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {values.project.country.toUpperCase()}
            </Typography>
          }
        />
        <Grid container spacing={1} className={classes.cardContent}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.cardProjectTitle}
          >
            <Typography
              className={classes.projectTitle}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {values.project.title_project}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              className={classes.cardProjectText}
              variant="body2"
              component="p"
            >
              {values.project.description}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <ButtonDefault
          id={values.project.id}
          type="greenMint"
          text={props.text}
          changeView={goToProject}
          disabled={buttonDisabled}
        >
          <ArrowRightAltIcon />
        </ButtonDefault>
      </CardActions>
    </Card>
  );
};
ProjectCard.propTypes = {
  image: PropTypes.string,
  title_project: PropTypes.string,
  description: PropTypes.string,
};

export default ProjectCard;
