import { makeStyles } from '@material-ui/core';
import { rectangle_8 } from './verifiedProjects.module';

const useStyles = makeStyles((theme) => ({
    rootVerified: {
        padding: '0px',
        margin: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '290px',
        width: '100%',
        maxWidth: '100%',
        borderRadius: '20px',
        border: 'none',
        backgroundImage: `url(${rectangle_8})`,
        backgroundSize: '100% 100%',
        marginTop: 28,
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            padding: '20px 14px 20px 14px',
            marginTop: 5,

        },
    },
    titleVerified: {
        fontFamily: 'MontserratBold',
        fontSize: '35px',
        fontWeight: 700,
        lineHeight: '42px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#FFFFFF',
        marginBottom: '10px'
    },
    subtitleVerified: {
        fontFamily: 'MontserratRegular',
        fontsize: '20px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#FFFFFF',
        marginBottom: '15px'
    },
    iconButtonVerified: {
        fontSize: '36.82px !important',
    },
    contentButtonVerified: {
        display: 'flex',
        justifyContent: 'center'
    }

}));

export default useStyles;
