import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr ',
    justifyItems: 'flex-start',
  },
  iconContainer_item: {
    marginBottom: '34px',
  },
  iconText_container: {
    margin: 0,
    padding: 0,
  },
  Title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '28px',
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
    width:'100%'

  },
  textData: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    width:'100%',
    fontWeight: 300,
  },
}));

export default useStyles;
