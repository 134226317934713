import { makeStyles } from '@material-ui/core';
import { backgroundImg } from './slide4.module';

const useStyles = makeStyles((theme) => ({
  containerSlide: {
    backgroundImage: `url(${backgroundImg})`,
    width: '100%',
    height: '691px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: '100% 100%', 
    borderTop: '123px',
    marginTop:'64px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  contentSlide:{
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
     paddingTop:25,
     paddingBottom:25,
    },
  },
  comingSoon: {
    display: 'flex',
    fontFamily: 'MontserratBold',
    fontSize: '60px',
    fontWeight: '700',
    color: '#06E89F',
    textAlign: 'left',
    lineHeight: '54.36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
      lineHeight: '42.36px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '41px',
      lineHeight: '42.36px',
    },
  },
  text: {
    display: 'flex',
    color: '#FFF',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '48px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '38px',
      lineHeight: '42px',

    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },
  buttonSlide:{
    marginTop: 70 ,
    display:'flex',
    justifyContent:'flex-start',
    [theme.breakpoints.down('md')]: {
      marginTop: 60 ,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 40 ,
    },
  }
}));

export default useStyles;
