import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Grid,
  Typography,
  Box,
  Container,
  Breadcrumbs,
  Card,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import Footer from '../footer/Footer';
import ButtonDefault from '../shared/button/button';
import CardProjects from '../shared/cardProjects/CardProjects';
import {
  ProjectDetailLogic,
  ProjectDetailService,
  validator,
  useStyles,
} from './projectDetail.module';
import DmrvChart from '../dmrvChart/dmrvChart';
import VerifiedProjects from '../verifiedProjects/verifiedProjects';
import KeysIndicatorsList from '../keyIndicatorsList/keyIndicatorsList';
import Impact from '../impact/impact';
import CreditExplanation from '../creditExplanation/creditExplanation';
import ProjectSpecsTable from '../projectSpecsTable/projectSpecsTable';
import InfoSensors from '../infoSensors/infoSensors';
import Documentation from '../Documentation/documentation';
import AboutEgreen from '../aboutEgreen/aboutEgreen';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import GuaranteeSection from '../GuaranteeSection/GuaranteeSection';
import { KeyIndicatorsService } from '../keyIndicatorsList/keyIndicators.module';
import ProjectCarrousell from '../shared/projectCarrousell/ProjectCarrousell';
import { useTranslation } from 'react-i18next';

// const MAX_LENGTH = 250;

const ProjectDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t: translator } = useTranslation();
  const [showFullText, setShowFullText] = useState(false);

  const {
    images,
    keys,
    loading,
    project,
    setPath,
    textForButton,
    translatedInfo,
    values,
  } = ProjectDetailLogic(
    () => console.log('callback'),
    validator,
    () => ProjectDetailService().getProject(id),
    () => KeyIndicatorsService().getData(id)
  );

  if (loading) {
    return null;
  }

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  const linkText = translator('view_more');

  return (
    <>
      <Container maxWidth="lg" className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>
              {translator('projectDetail.home')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to="/projects">
            <Typography className={classes.fontLink}>
              {translator('projectDetail.project')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to={`/project/${id}`}>
            <Typography className={classes.fontLink}>
              {translator('projectDetail.description')}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Container>

      <Container className={classes.root}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12} align="left">
            <form className={classes.fromProject}>
              <Typography variant="h3" className={classes.projectTitle}>
                <div
                  dangerouslySetInnerHTML={{ __html: translatedInfo.name }}
                />
              </Typography>
              <Typography variant="h4" className={classes.countryText}>
                <div
                  dangerouslySetInnerHTML={{ __html: translatedInfo.country }}
                />
              </Typography>
              <Typography variant="body1" className={classes.descriptionText}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: translatedInfo.description,
                  }}
                />
                {project.url_external_info && (
                  <a
                    href={project.url_external_info}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.customLink}
                    onClick={handleToggleText}
                    style={{ display: 'inline', marginLeft: '5px' }}
                  >
                    {linkText}
                  </a>
                )}
              </Typography>
              <div className={classes.contentButtonProjectDetail}>
                <ButtonDefault
                  type="greenMint"
                  text={translator(textForButton || 'projectDetail.forward')}
                  isSubmit={true}
                  disabled={
                    values.project.only_showing ||
                    values.project.show_without_purchase
                  }
                  changeView={setPath(id)}
                  children={<ArrowRightAltIcon />}
                ></ButtonDefault>
              </div>
            </form>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.imageGallery}>
              <ImageGallery
                thumbnailPosition="bottom"
                showIndex={false}
                showPlayButton={false}
                showBullets={false}
                showFullscreenButton={false}
                showNav={false}
                items={images.map((image) => ({
                  ...image,
                  originalClass: classes.image,
                  thumbnailClass: classes.imageGalleryThumbnail,
                  thumbnailContainerClass: `${classes.imageGalleryThumbnailContainer} ${classes.imageGalleryThumbnailContainerHover}`,
                  thumbnailWidth: 70,
                  thumbnailHeight: 60,
                }))}
              />
            </Box>
          </Grid>

          {values.project.version === 'v2' && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Impact impacts={values.project.impacts} />
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <KeysIndicatorsList info={values} keys={keys} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProjectSpecsTable info={values} keys={keys} />
          </Grid>
        </Grid>
      </Container>
      <ProjectCarrousell />

      <Container className={classes.root}>
        <Grid container spacing={2}>
          {values.project.version === 'v2' && (
            <>
              {values.project.project_name === 'Esmeralda Rosa' ? (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <GuaranteeSection />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <GuaranteeSection />
                  </Grid>
                </>
              )}
              {values.project.project_name === 'Esmeralda Rosa' && (
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.containerAboutEgreen}
                  >
                    <AboutEgreen name={values.project.project_name} />
                  </Grid>
                </>
              )}
              {values.project.project_name !== 'Esmeralda Rosa' && (
                <>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.containerAboutEgreen}
                  >
                    <AboutEgreen />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Card className={classes.chartDmrv}>
                      <DmrvChart />
                    </Card>
                  </Grid>
                </>
              )}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.rootInfoSensors}
              >
                <InfoSensors translatedInfo={translatedInfo} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={classes.containerTextDocumentation}>
                  <Typography variant="h3" className={classes.titleIPFS}>
                    {translator('projectDetails.titleIPFS')}
                  </Typography>
                  <Typography className={classes.locationText}>
                    {values.project.project_name !== 'Esmeralda Rosa'
                      ? translator('projectDetails.text')
                      : translator('projectDetails.text_esmeralda')}
                  </Typography>
                  <Typography className={classes.locationFootNote}>
                    {translator('projectDetails.footnote')}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.documentProjectDetail}
              >
                <Documentation values={values} />
              </Grid>
            </>
          )}

          {values.project.version === 'v2' &&
            translatedInfo.commitment_to_guidelines &&
            translator('projectDetails.creditExplanationTitle') && (
              <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                className={classes.rootInfoSensors}
              >
                <CreditExplanation
                  title={
                    translator('projectDetails.creditExplanationTitle') || null
                  }
                  nft_description={
                    translatedInfo.commitment_to_guidelines || null
                  }
                />
              </Grid>
            )}

          <VerifiedProjects />
          <Grid item lg={12} sm={12} xs={12}>
            <Box mt={3}>
              <Typography variant="h3" className={classes.sectionOtherProjects}>
                {translator('projectDetail.other_projects')}
              </Typography>
            </Box>
            <Box className={classes.projectContainer}>
              <CardProjects
                type={'Minor'}
                text={translator('projectDetail.go_to_projects')}
                id={id}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ProjectDetail;
